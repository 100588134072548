import React, { useReducer, createContext, useEffect } from "react"
import userReducer from "../reducers/user-reducer"
import axios from "axios"
import CircularProgress from "@material-ui/core/CircularProgress"
import Box from "@material-ui/core/Box"

import { setUser } from "../actions"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  outBox: {
    width: "100%",
    minHeight: "calc(100svh  - 66px)",
    position: "relative",
    overflowY: "scroll",
  },

  inBox: {
    width: "80%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },
}))

export const UserContext = createContext()
const UserProvider = UserContext.Provider

export function UserWrapper({ children }) {
  const [loading, setLoading] = React.useState(true)

  const classes = useStyles()

  const defaultUser = { userDisplayName: "Guest" }
  const storedUser =
    typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("user"))
      : null
  const [user, dispatchUser] = useReducer(
    userReducer,
    storedUser || defaultUser
  )

  useEffect(() => {
    const defaultUserConst = { userDisplayName: "Guest" }
    const storedUserConst = JSON.parse(localStorage.getItem("user"))
    if (storedUserConst) {
      axios
        .get(process.env.GATSBY_STRAPI_URL + "/api/users/me", {
          headers: {
            Authorization: `Bearer ${storedUserConst.jwt}`,
          },
        })
        .then(response => {
          setLoading(false)
          dispatchUser(
            setUser({
              ...response.data,
              jwt: storedUserConst.jwt,
              onboarding: true,
            })
          )
        })
        .catch(error => {
          setLoading(false)
          console.error(error)
          if (error.code !== "ERR_NETWORK") {
            dispatchUser(setUser(defaultUserConst))
          }
        })
    } else {
      setLoading(false)
    }
  }, [])

  return loading ? (
    <Box classes={{ root: classes.outBox }}>
      <Box classes={{ root: classes.inBox }}>
        <CircularProgress color="primary" />
      </Box>
    </Box>
  ) : (
    <UserProvider value={{ user, dispatchUser, defaultUser }}>
      {children}
    </UserProvider>
  )
}
